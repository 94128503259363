import React from "react";
import { useState } from "react";
import Card from "./Card";
import BlockContent from "../BlockContent/BlockContent";
import Modal from "../Modal/Modal";
import "./CardGroup.scss";

const CardGroup = ({ cardGroupData }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState([{}]);

    const openModal = (content) => {
        setModalOpen(true);
        setModalContent(content);
    };

    return (
        <>
            <Modal setModalOpen={setModalOpen} modalOpen={modalOpen} modalContent={modalContent} />
            <div className={`card-group ${cardGroupData.extraWide ? "card-group--wide" : ""}`}>
                <div className="card-group__heading">
                    <BlockContent blocks={cardGroupData.title} />
                </div>
                <div
                    className={`card-group__container ${
                        cardGroupData.extraWide ? "card-group__container--5-wide" : ""
                    }`}
                >
                    {cardGroupData.cardList.map((card, key) => {
                        return (
                            <Card
                                key={`${card?.heading}-${key}`}
                                cardData={card}
                                openModal={openModal}
                                fiveWide={cardGroupData.extraWide}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default CardGroup;
