import React from "react";
import { useRef, useState } from "react";
import BlockContent from "../BlockContent/BlockContent";
import Header from "../Header/Header";
import "./Modal.scss";

const Modal = ({ modalOpen, setModalOpen, modalContent }) => {
    // const [modalOpen, setModalOpen] = useState("");
    const modalRef = useRef(null);
    const overlayRef = useRef(null);

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <div className={`modal ${modalOpen ? "open" : "closed"}`}>
                <div className="modal-inner">
                    <div className="content" useRef={modalRef}>
                        <div className="modal-scroll">
                            {modalContent?.heading && (
                                <div className="content__header">
                                    <BlockContent blocks={modalContent.heading} />
                                </div>
                            )}
                            {modalContent?.modalBody && (
                                <div className="content__body">
                                    {" "}
                                    <BlockContent blocks={modalContent.modalBody} />
                                </div>
                            )}
                            {modalContent?.footer && (
                                <div className="content__footer">{modalContent.footer}</div>
                            )}
                            <svg
                                className="close-vector"
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="17"
                                    cy="17"
                                    r="16.5952"
                                    fill="#F8F8F8"
                                    stroke="#FB7448"
                                    strokeWidth="0.809524"
                                />
                                <path
                                    d="M11.9996 12L22.8755 22.876"
                                    stroke="#FB7448"
                                    strokeWidth="1.00311"
                                />
                                <path
                                    d="M22.876 12L12 22.876"
                                    stroke="#FB7448"
                                    strokeWidth="1.00311"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <div
                    ref={overlayRef}
                    className={"modal-overlay" + (modalOpen ? " open" : "")}
                    // className={"modal-overlay open"}
                    role="button"
                    tabIndex={0}
                    onClick={() => closeModal()}
                ></div>
            </div>
        </>
    );
};

export default Modal;
