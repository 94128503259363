import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/base.scss";
import ColumnStatic from "../components/TwoColumns/Columns/ColumnStatic/ColumnStatic";
import TwoColumns from "../components/TwoColumns/TwoColumns";
import SocialProofMarquee from "../components/SocialProofMarquee/SocialProofMarquee";
import CardGroup from "../components/CardGroup/CardGroup";
import ContentBlock from "../components/ContentBlock/ContentBlock";
import StaticStats from "../components/StaticStats.js/StaticStats";
import FullTextBlock from "../components/FullTextBlock/FullTextBlock";

const aboutUs = ({ errors, data, location }) => {
    if (errors) return <Layout errors={errors} />;

    const { page } = data;
    const modulesData = page.pageContent.modules;

    const href = location.href;

    const {
        description: seoDescription,
        keywords: seoKeywords,
        noindex: seoNoIndex,
        title: seoTitle,
        data: seoData,
        altCanonical,
    } = page.pageContent.seo;

    const seoPageData = {
        title: seoTitle ? seoTitle : page.title,
        description: seoDescription ? seoDescription : "",
        keywords: seoKeywords ? seoKeywords : null,
        noIndex: seoNoIndex ? seoNoIndex : false,
        data: seoData ? seoData : false,
        altCanonical: altCanonical,
        href: href,
    };

    const ModuleComponents = modulesData.map((module, key) => {
        switch (module._type) {
            case "twoColumns":
                const twoCol = <TwoColumns twoColumnsData={module} key={key} />;
                return twoCol;
            case "socialProofMarquee":
                const social = (
                    <SocialProofMarquee socialProofData={module} fullWidth={true} key={key} />
                );
                return social;
            case "cardGroup":
                const cards = <CardGroup cardGroupData={module} key={key} />;
                return cards;
            case "fullTextBlock":
                return <FullTextBlock fullTextBlockData={module} key={key} />;
            default:
                return null;
        }
    });

    const cta = {
        text: "Join our team!",
        externalLink: false,
        href: "/careers",
        ariaLabel: "Join our team",
        additionalClasses: "accent",
    };
    const quoteContent = (
        <div className="content-text">
            <p>
                “The industry has evolved quickly and is frequently introducing new compliance laws
                that directly impact restaurateurs, big and small. With 7shifts’ core offering being
                scheduling, it was only natural to go deeper on supporting all aspects related to
                scheduling compliance. 7shifts partners closely with compliance lawyers across the
                US to firmly understand the regulation requirements by state. From that, 7shifts
                builds out the necessary reporting and alerting directly into a simple team
                management product that all layers of management can access.”
            </p>
            <p className="accent column-top-padding">Jordan Boesch, CEO</p>
        </div>
    );

    const leftContent = (
        <div className="content-image">
            <StaticImage
                src={"../images/jordan-quote.png"}
                alt="Jordan Boesch, CEO"
                auto="format"
                placeholder="blurred"
            />
        </div>
    );
    const quote = (
        <>
            <div className="content-title content-title--left">
                <h2>The 7shifts story</h2>
            </div>

            <div className="content-text">
                <p>
                    7shifts was founded in 2014 by Jordan Boesch while working for his dad at
                    Quiznos. He witnessed the pains of building schedules first-hand while watching
                    his dad manage and schedule several employees across his two locations. It was
                    at that point that he began building 7shifts.
                </p>
                <p>
                    7shifts exists with a core purpose - To simplify team management and improve
                    performance for restaurants, freeing up time for managers to focus on serving
                    their customers. 7shifts focuses 100% on the restaurant industry and works with
                    best-in-class technology partners to seamlessly integrate all of the stages in
                    the employee lifecycle - from hiring, training, scheduling, paying and
                    engaging/retaining.
                </p>
            </div>
            <div className="content-lr-wrapper">
                <div className="content-lr-30">{leftContent}</div>
                <div className="content-lr-70">{quoteContent}</div>
            </div>
            <div className="content-text">
                <p>
                    Today, 7shifts is used by over 25,000 restaurants across North America and is
                    providing over 500,000 restaurant workers with engaging and easy-to-use app, all
                    while giving managers the peace-of-mind they deserve.
                </p>
            </div>
            <div className="content--left">
                <a href="/book-demo" className="global-cta global-cta--secondary">
                    Chat with us
                </a>
            </div>
        </>
    );

    const customerStats = {
        title: (
            <h3>
                Our customers <span className="accent">by the numbers</span>
            </h3>
        ),
        statOne: {
            icon: (
                <StaticImage
                    src={"../images/scheduling-icon.png"}
                    alt="Scheduling Icon"
                    auto="format"
                    width={20}
                    height={20}
                    config={{ dpr: 1 }}
                />
            ),
            number: "200 mm",
            text: "Shifts published",
        },
        statTwo: {
            icon: (
                <StaticImage
                    src={"../images/clock-icon.png"}
                    alt="Clock Icon"
                    auto="format"
                    width={20}
                    height={20}
                    config={{ dpr: 1 }}
                />
            ),
            number: "1 billion",
            text: "Hours scheduled",
        },
        statThree: {
            icon: (
                <StaticImage
                    src={"../images/dollar-icon.png"}
                    alt="Dollar Icon"
                    auto="format"
                    width={20}
                    height={20}
                    config={{ dpr: 1 }}
                />
            ),
            number: "13 billion",
            text: "Paid from shifts scheduled",
        },
    };

    const companyCulture = (
        <>
            <div className="content-lr-wrapper">
                <div className="content-lr-50 content-pr">
                    <h2>Our Company Culture</h2>
                    <h2 className="h1">
                        A <span className="accent">world-class team</span> means a world-class
                        product.
                    </h2>
                    <div className="static-image-border">
                        <StaticImage
                            src={"../images/world-class-team.png"}
                            alt="7shifts Company photo"
                            auto="format"
                            placeholder="blurred"
                        />
                    </div>
                </div>
                <div className="content-lr-50">
                    <ColumnStatic
                        innerText={[
                            "We actively seek out and invest in our team at 7shifts. We believe that we can provide exponential value to restaurants everywhere when we bring together the smartest, most passionate people to get the job done. That’s why 7shifts has been ranked the",
                            <span className="accent">#1 Best Workplace in Canada</span>,
                            ". We strive to be an inclusive and exciting workplace, where our team is given the opportunity to grow, and work on problems that have a real impact for the restaurants we serve.",
                        ]}
                        cta={cta}
                    ></ColumnStatic>
                </div>
            </div>
        </>
    );

    return (
        <Layout
            seoData={seoPageData}
            heroContent={page.pageContent.hero}
            pageHeading={page.pageContent.pageHeading}
            hideForm
        >
            <ContentBlock content={quote} />
            {ModuleComponents[0]}
            <StaticStats statsData={customerStats} />
            {ModuleComponents[1]}
            {ModuleComponents[2]}
            {ModuleComponents[3]}
            {ModuleComponents[4]}
            {ModuleComponents[5]}
            {ModuleComponents[6]}
            <ContentBlock content={companyCulture} />
        </Layout>
    );
};

export default aboutUs;

export const query = graphql`
    query aboutUsQuery {
        page: sanityPage(pageTitle: { eq: "About Us" }) {
            title: pageTitle
            slug {
                current
            }
            id
            pageContent {
                seo {
                    description
                    title
                    keywords
                    noindex
                    data
                }
                hero: heroBanner {
                    eyebrow
                    title: heroTitle {
                        style
                        _key
                        _type
                        children {
                            _type
                            _key
                            marks
                            text
                        }
                    }
                    marqueeText
                    marqueeImages {
                        ...ImageWithPreview
                        asset {
                            metadata {
                                dimensions {
                                    width
                                    height
                                }
                            }
                            url
                            filename
                        }
                    }
                    primaryImage {
                        ...ImageWithPreview
                    }
                    primaryalt
                    secondaryImage {
                        ...ImageWithPreview
                    }
                    secondaryalt
                    backdropImage {
                        ...ImageWithPreview
                    }
                    ctaButtonOne {
                        linkTo {
                            external
                            internal {
                                slug {
                                    current
                                }
                            }
                        }
                        title
                    }
                    ctaButtonTwo {
                        title
                        linkTo {
                            external
                            internal {
                                slug {
                                    current
                                }
                            }
                        }
                    }
                    subtext
                }
                modules {
                    ... on SanityTestimonials {
                        _key
                        _type
                        testimonialList {
                            jobTitle
                            name
                            quote
                            logoAlt
                            image {
                                ...ImageWithPreview
                            }
                            logo {
                                ...ImageWithPreview
                            }
                        }
                    }
                    ... on SanityDemoForm {
                        _key
                        _type
                        showDemoForm
                    }
                    ... on SanityStats {
                        backdropImage {
                            ...ImageWithPreview
                        }
                        _key
                        _type
                        title {
                            style
                            list
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        statOne {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                        statThree {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                        statTwo {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                    }
                    ... on SanityAccordian {
                        _key
                        _type
                        title: accordianTitle {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        list {
                            title
                            description {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    text
                                }
                            }
                        }
                    }
                    ... on SanitySocialProofMarquee {
                        _key
                        _type
                        title: headingText {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        useCustomLogos
                        useCustomHeading
                        socialProofImages {
                            ...ImageWithPreview
                            asset {
                                metadata {
                                    dimensions {
                                        width
                                        height
                                    }
                                }
                                url
                                filename
                            }
                        }
                    }
                    ... on SanityFullTextBlock {
                        _key
                        _type
                        heading {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        body {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        internalLink
                        ctaText
                        ctaLink
                    }
                    ... on SanityTwoColumns {
                        _key
                        _type
                        swappable {
                            _type
                            _key
                            title: titleWithAccent {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            columnOne {
                                columnTypes {
                                    ... on SanityColumnDetails {
                                        _key
                                        _type
                                        simpleLink {
                                            external
                                            internal {
                                                slug {
                                                    current
                                                }
                                            }
                                            title
                                        }
                                        text
                                        title: titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                    }
                                    ... on SanityColumnImages {
                                        _key
                                        _type
                                        image {
                                            ...ImageWithPreview
                                        }
                                        alt
                                        backdropImage {
                                            ...ImageWithPreview
                                        }
                                        larger
                                        marginTop
                                        popups {
                                            headline
                                            text
                                        }
                                    }
                                    ... on SanitySubLinks {
                                        _key
                                        _type
                                        subLinksList {
                                            icon {
                                                ...ImageWithPreview
                                            }
                                            simpleLink {
                                                external
                                                title
                                                internal {
                                                    slug {
                                                        current
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ... on SanitySubColumns {
                                        _key
                                        _type
                                        subColumnsList {
                                            title
                                            text
                                            image {
                                                ...ImageWithPreview
                                            }
                                        }
                                    }
                                }
                            }
                            columnTwo {
                                columnTypes {
                                    ... on SanityColumnDetails {
                                        _key
                                        _type
                                        simpleLink {
                                            external
                                            internal {
                                                slug {
                                                    current
                                                }
                                            }
                                            title
                                        }
                                        text
                                        title: titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                    }
                                    ... on SanityColumnImages {
                                        _key
                                        _type
                                        image {
                                            ...ImageWithPreview
                                        }
                                        alt
                                        backdropImage {
                                            ...ImageWithPreview
                                        }
                                        larger
                                        popups {
                                            headline
                                            text
                                        }
                                    }
                                    ... on SanitySubLinks {
                                        _key
                                        _type
                                        subLinksList {
                                            icon {
                                                ...ImageWithPreview
                                            }
                                            simpleLink {
                                                external
                                                title
                                                internal {
                                                    slug {
                                                        current
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ... on SanitySubColumns {
                                        _key
                                        _type
                                        subColumnsList {
                                            title
                                            text
                                            image {
                                                ...ImageWithPreview
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on SanityCardGroup {
                        _key
                        _type
                        title: cardGroupTitle {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        extraWide
                        cardList {
                            body {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            modalBody {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            ctaLink
                            ctaText
                            ctaOpensModal
                            internalLink
                            heading {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            iconAlt
                            icon {
                                asset {
                                    _id
                                    _key
                                    url
                                    filename
                                    metadata {
                                        dimensions {
                                            aspectRatio
                                            height
                                            width
                                        }
                                    }
                                }
                            }
                            _type
                            showBoxShadow
                        }
                    }
                    ... on SanityThreeColumns {
                        _key
                        _type
                        threeColumnOne {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                        threeColumnTwo {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                        threeColumnThree {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                    }
                }
                pageHeading {
                    style
                    _key
                    _type
                    children {
                        _type
                        _key
                        marks
                        text
                    }
                }
            }
        }
    }
`;
