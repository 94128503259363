import anime from "animejs";
import { Link } from "gatsby";
import React from "react";
import { useIntersect } from "../../../../hooks/useIntersect";
import "./ColumnStatic.scss";

const ColumnStatic = ({ eyebrow, headingText, innerText, content, cta, bottomContent }) => {
    const animate = (entry) => {
        if (!entry || !entry.target) return;

        anime({
            targets: entry.target.querySelectorAll("*"),
            opacity: [0, 1],
            translateX: [-20, 0],
            easing: "easeOutCirc",
            delay: anime.stagger(100),
        });
    };

    const ioCallback = (entry, io) => {
        if (entry && entry.isIntersecting) {
            animate(entry);
            io.disconnect();
        }
    };

    const [ioRef, _entry] = useIntersect({
        callback: ioCallback,
    });

    return (
        <div className="col-static-wrapper" ref={ioRef}>
            {eyebrow && <div className="column-eyebrow">{eyebrow}</div>}

            {headingText && <h3>{headingText}</h3>}

            {innerText && <div className="column-text">{innerText}</div>}
            {content && <div className="column-content">{content}</div>}
            {cta && (
                <div className="column-cta">
                    {cta.externalLink && (
                        <a
                            aria-label={cta.ariaLabel}
                            href={cta.href}
                            target="_blank"
                            rel="noopener"
                            className={`global-cta ${
                                cta.additionalClasses ? cta.additionalClasses : ""
                            }`}
                        >
                            {cta.text}
                        </a>
                    )}
                    {!cta.externalLink && (
                        <Link
                            aria-label={cta.ariaLabel}
                            to={cta.href}
                            className={`global-cta ${
                                cta.additionalClasses ? cta.additionalClasses : ""
                            }`}
                        >
                            {cta.text}
                        </Link>
                    )}
                </div>
            )}
            {bottomContent && <div className="bottom-content">{bottomContent}</div>}
        </div>
    );
};

export default ColumnStatic;
