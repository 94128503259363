import React from "react";
import BlockContent from "../BlockContent/BlockContent";
import { Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import "./Card.scss";

const Card = ({ cardData, openModal, fiveWide }) => {
    return (
        <div
            className={`card ${cardData?.showBoxShadow ? "card--glow" : ""} ${
                fiveWide ? "card--5-wide" : ""
            }`}
        >
            <div className="card__content">
                <div className="card__header">
                    <BlockContent blocks={cardData.heading} />
                </div>
                <div className="card__body">
                    <BlockContent blocks={cardData.body} />
                </div>
                <div
                    className={`card__footer ${
                        cardData.icon && !cardData.ctaText ? "card__footer--icon" : ""
                    }`}
                >
                    {cardData.ctaText &&
                        !cardData?.ctaOpensModal &&
                        (cardData.internalLink ? (
                            <Link
                                aria-label={cardData.ctaText}
                                to={cardData.ctaLink}
                                className="global-button--outline"
                            >
                                {cardData.ctaText}
                            </Link>
                        ) : (
                            <a
                                aria-label={cardData.ctaText}
                                href={cardData.ctaLink}
                                target="_blank"
                                rel="noopener"
                                className="global-button--outline"
                            >
                                {cardData.ctaText}
                            </a>
                        ))}

                    {cardData.ctaOpensModal && (
                        <button
                            type="submit"
                            className="global-button--outline"
                            onClick={() => openModal(cardData)}
                        >
                            {cardData?.ctaText ? cardData.ctaText : "Start free trial"}
                        </button>
                    )}
                    {cardData.icon && !cardData.ctaText && (
                        <img
                            className="card__icon"
                            src={cardData.icon.asset.url + "?q=75&auto=format&dpr=1"}
                            // height={cardData.icon.asset.metadata.dimensions.height}
                            // width={cardData.icon.asset.metadata.dimensions.width}
                            style={{
                                aspectRatio: `${cardData.icon.asset.metadata.dimensions.width} / ${cardData.icon.asset.metadata.dimensions.height}`,
                            }}
                            alt={cardData.iconAlt}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Card;
