import React from "react";
import "./StaticStats.scss";

const StaticStats = ({ statsData }) => {
    const { title, statOne: one, statTwo: two, statThree: three } = statsData;

    const Stats = Array.from([one, two, three]).map((stat, key) => (
        <div className="staticstat" key={key}>
            <div className="number">
                {stat.icon && <div className="icon">{stat.icon}</div>}
                <span className="">{stat.number}</span>
            </div>
            <div className="text">{stat.text}</div>
        </div>
    ));

    return (
        <div className="staticstats-wrapper">
            <div>
                <div className="staticstats-inner">
                    <div className="title-wrapper">{title}</div>
                    <div className="staticstat-numbers">{Stats}</div>
                </div>
            </div>
            {/* <div className="backdrop">
                {statsData.backdropImage && <Image className="backdrop-img" {...statsData.backdropImage} width={300} height={300} config={{ dpr: 2 }} alt="stats backdrop image"/>}
            </div> */}
        </div>
    );
};

export default StaticStats;
